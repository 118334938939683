import { FC, HTMLProps } from "react";
import styles from "./Placeholder.module.scss";
import classNames from "classnames";
import { ReactComponent as Logo } from "../Logo/tevian.svg";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Placeholder: FC<Props> = (props) => {
  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <Logo className={styles["logo"]} />
    </div>
  );
};

export default Placeholder;
