import { createSlice } from "@reduxjs/toolkit";
import type { AppDispatch } from "../index";
import { AlertColor } from "@mui/material";

type MessageType = {
  type: AlertColor;
  message: string;
};

const initialState: MessageType = {
  type: "info",
  message: "",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotif: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    clearNotif: (state) => {
      state.message = "";
      state.type = "info";
    },
  },
});

export const setNoDevices = () => async (dispatch: AppDispatch) => {
  dispatch(setNotif({ message: "Camera has not been found!", type: "error" }));
};

export const { setNotif, clearNotif } = notificationSlice.actions;

export default notificationSlice.reducer;
