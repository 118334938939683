import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#036ab5",
      dark: "#263b47",
      light: "#ffffff",
    },
    secondary: {
      main: "#57555b",
      dark: "#98979e",
      light: "#d8e3ec",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Suisse Intl';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src:  url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
          url("./assets/fonts/SuisseIntl/SuisseIntl-Regular.woff") format("woff");
        }

        @font-face {
          font-family: Suisse Intl;
          font-weight: 500;
          font-display: swap;
          src: url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff2") format("woff2"),
            url("./assets/fonts/SuisseIntl/SuisseIntl-Medium.woff") format("woff");
        }
      `,
    },
  },
});

export default theme;
