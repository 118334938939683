const createDetector = () => {
  const detector = (window as any).TevianFaceWasm({
    locateFile: (path: string, prefix: string) => {
      if (path === "facewasm.wasm") {
        return window.location.origin + "/facewasm/facewasm.wasm";
      }
      if (path === "facewasm.data") {
        return window.location.origin + "/facewasm/facewasm.data";
      }
      return prefix + path;
    },
    log: (...args: []) => console.log(...args),
    error: (...args: []) => console.error(...args),
    onRuntimeInitialized: () => {
      console.log("initialized");
    },
  });
  return detector;
};

export default createDetector;
