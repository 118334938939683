import { useDispatch } from "react-redux";
import { setNotif } from "../store/reducers/notificationReducer";
import { useTranslation } from "react-i18next";
import { AlertColor } from "@mui/material";

export type NotifyType = (type: AlertColor, message: string) => void;

// создает функцию уведомлений, которая будет передаваться в spoofme
const useNotify = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notify: NotifyType = (type, message) => {
    const translatedMessage = t(`notification.${message}`);
    dispatch(setNotif({ type, message: translatedMessage }));
  };

  return {
    notify,
  };
};

export default useNotify;
