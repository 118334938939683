import { useState } from "react";

const useAgreement = () => {
  const [warningIsShown, setWarningIsShown] = useState(false); // показать баннер согласия
  const [userAgreed, setUserAgreed] = useState(false); // юзер согласился с условиями использования

  const showWarning = () => {
    setWarningIsShown(true);
  };

  const hideWarning = () => {
    setWarningIsShown(false);
  };

  return {
    warningIsShown,
    showWarning,
    userAgreed,
    setUserAgreed,
    hideWarning,
  };
};

export default useAgreement;
