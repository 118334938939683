import { useDispatch, useSelector } from "../hooks/reduxHooks";
import { Snackbar, Alert, Slide, SlideProps } from "@mui/material";
import { clearNotif } from "../store/reducers/notificationReducer";

type TransitionProps = Omit<SlideProps, "direction">;

function Transition(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

function Message() {
  const notifMessage = useSelector((state) => state.notification.message);
  const notifType = useSelector((state) => state.notification.type);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearNotif());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ position: "absolute" }}
      open={Boolean(notifMessage)}
      TransitionComponent={Transition}
      onClose={handleClose}
    >
      <Alert severity={notifType} sx={{ width: "100%" }}>
        {notifMessage}
      </Alert>
    </Snackbar>
  );
}

export default Message;
