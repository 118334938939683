import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// контролирует переключение камеры с фронтальной на заднюю
const useSwitch = () => {
  const { t } = useTranslation();
  const [switchText, setSwitchText] = useState(t("button.switch"));
  const [camera, setCamera] = useState("user");
  const [switchAllowed, setSwitchAllowed] = useState(false); // устройство имеет больше 1 камеры
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]); // обнаруженные камеры
  const [blockSwitch, setBlockSwitch] = useState(false);
  const [currentStream, setCurrentStream] = useState<MediaStream>();

  const handleSwitch = () => {
    if (currentStream) {
      currentStream.getTracks().forEach((t) => t.stop());
    }
    setBlockSwitch(true);
    setCamera((newCam) => {
      if (newCam === "user") {
        return "environment";
      }
      return "user";
    });
  };

  const handleDevices = (value: any) => {
    setCameraList(value);
  };

  const updateStream = (stream: MediaStream) => {
    setCurrentStream(stream);
  };

  // меняет перевод на кнопке переключения камеры
  useEffect(() => {
    setSwitchText(t("button.switch"));
  }, [t]);

  return {
    camera,
    handleSwitch,
    switchAllowed,
    setSwitchAllowed,
    handleDevices,
    cameraList,
    blockSwitch,
    setBlockSwitch,
    switchText,
    updateStream,
  };
};

export default useSwitch;
