import React from "react";
import { NotifyType } from "../hooks/useNotify";

type CustomHeaders = HeadersInit & {
  "Content-Type": string;
  "Content-Length": string;
  "liveness-bbox": string;
};

type Request = RequestInit & {
  headers: CustomHeaders;
};

type RetryFetchArgs = [string, Request];

type Bbox = {
  x: number;
  y: number;
  w: number;
  h: number;
};

type DetectionFlags = [boolean, Date][];

type Refs = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
};

type ReactFunctions = {
  notify: NotifyType;
  tryAgain: () => void;
  setSwitchAllowed: (bol: boolean) => void;
  handleDevices: (value: any) => void;
  setBlockSwitch: (bol: boolean) => void;
  updateStream: (stream: MediaStream) => void;
};

const apiUrl = "/api/v1/";

// init state for detect params
let minSide = 0;
let roiSide = 0;
let staticDetectParams = {
  roi: {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  },
  minSize: 0,
};
// init state for view roi
let viewMinSide = 0;
let viewRoiSide = 0;
let visibleRoi = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
};

let yaw: number | null = null;
let pitch: number | null = null;
let latestBboxes: Bbox[] = [];
let detectionFlags: [boolean, Date][] = [];
let livenessScores: number[] = [];
let prevLivenessTimestamp: Date | null = null;
let calcLivenessScoreCalled = 0;

// Константы задачи
const task = {
  angles: {
    yaw: 0,
    pitch: 0,
  },
  qualityThreshold: 0.2,
  yawThreshold: 25,
  pitchThreshold: 10,
  matchTimeout: 2 * 1000,
  successDetectionRate: 0.5,
  failDetectionRate: 0.5,
  smoothBboxLength: 4,
  calcLivenessScoreInterval: 500,
  calcLivenessScoreCount: 4,
};

// Promise для задержки. Нужен для многократных попыток отправки на сервер
// данных
const delay = (timeout: number) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

// В случае ошибки сети ждем и повторяем снова
const retryFetch = async (...args: RetryFetchArgs) => {
  let timeout = 2000;
  while (true) {
    const response = await fetch(...args);
    if (response.ok) {
      return response.json();
    } else {
      await delay(timeout);
      timeout += 2000;
    }
  }
};

// Усреднение bbox между кадрами для стабильности
const meanBbox = (latestBboxes: Bbox[]) => {
  if (latestBboxes.length === 0) {
    return;
  }

  let outX = 0;
  let outY = 0;
  let outW = 0;
  let outH = 0;
  for (let { x, y, w, h } of latestBboxes) {
    outX += x;
    outY += y;
    outW += w;
    outH += h;
  }

  return {
    x: outX / latestBboxes.length,
    y: outY / latestBboxes.length,
    w: outW / latestBboxes.length,
    h: outH / latestBboxes.length,
  };
};

// Сохранение успешности вызова detect, чтобы усреднять за несколько кадров для
// стабильности
const addDetectionFlags = (detectionFlags: DetectionFlags, isGood: boolean) => {
  const ts = new Date();
  detectionFlags = detectionFlags.filter(
    (x) => +ts - +x[1] > task.matchTimeout
  );
  detectionFlags.push([isGood, ts]);
  return detectionFlags;
};

// Усреднение для стабильности
const meanDetectionFlag = (detectionFlags: DetectionFlags) => {
  let flag = 0;
  for (let [f, _] of detectionFlags) {
    flag += +f;
  }
  return flag / detectionFlags.length;
};

// Усреднение для стабильности
const meanLivenessScore = (livenessScores: number[]) => {
  let sum = 0;
  for (let score of livenessScores) {
    sum += score;
  }
  return sum / livenessScores.length;
};

// tries to use device resolution metainformation to set exact maximal resolution
// NOTE: use of default video config may cause unexpected behavior
// for example, width and height of video may be equal wrong numbers
const getCameraStream = async (
  cameraIdx: string,
  functions: ReactFunctions,
  cameraList: MediaDeviceInfo[]
) => {
  let videoDevs = null;
  // get user devices
  if (!cameraList.length) {
    // iOS doesn't return all devices available without this call
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        frameRate: 30,
        // FullHD resolution
        width: 1920,
        height: 1080,
      },
    });
    stream.getTracks().map((t) => t.stop());
    const devs = await navigator.mediaDevices.enumerateDevices();
    videoDevs = devs.filter((d) => d.kind === "videoinput");
    functions.handleDevices(videoDevs);
  } else {
    videoDevs = cameraList;
  }

  functions.setSwitchAllowed(videoDevs.length > 1); // show camera switch button

  // check back camera
  let mirror = true; // turn on mirroring for frontal cam
  if (cameraIdx === "environment") {
    mirror = false; // turn off mirroring
  }

  // get stream from particular device
  const stream = await navigator.mediaDevices
    .getUserMedia({
      audio: false,
      video: {
        frameRate: 30,
        // FullHD resolution
        width: 1920,
        height: 1080,
        facingMode: cameraIdx || "user",
      },
    })
    .then((stream) => {
      functions.notify("success", "camAllowed");
      functions.updateStream(stream);
      return stream;
    })
    .catch((err) => {
      functions.notify("error", "camError");
      throw new Error(err);
    });
  return { userMedia: stream, mirror };
};

const applyHorizontalVideoFlip = (
  mirror: boolean,
  viewCtx: CanvasRenderingContext2D,
  detectCtx: CanvasRenderingContext2D,
  viewWidth: number,
  detectCanvas: HTMLCanvasElement
) => {
  let scale = -1;
  /*
  При отзеркаливании необходимо перенести
  отзеркаливаемый объект на ширину изображения
  */
  let translate = 1;
  if (!mirror) {
    scale = 1;
    translate = 0;
  }
  viewCtx.translate(viewWidth * translate, 0);
  viewCtx.scale(scale, 1);
  detectCtx.translate(detectCanvas.width * translate, 0);
  detectCtx.scale(scale, 1);
};

const initDetectParams = (
  minSide: number,
  detectCanvas: HTMLCanvasElement,
  viewWidth: number,
  viewHeight: number
) => {
  minSide = Math.min(detectCanvas.width, detectCanvas.height);
  roiSide = 0.6 * minSide;
  staticDetectParams = {
    roi: {
      x: Math.floor(0.5 * detectCanvas.width - 0.7 * roiSide),
      y: Math.floor(0.5 * detectCanvas.height - 0.7 * roiSide),
      w: Math.ceil(1.4 * roiSide),
      h: Math.ceil(1.4 * roiSide),
    },
    minSize: Math.floor(0.5 * roiSide),
  };
  viewMinSide = Math.min(viewWidth, viewHeight);
  viewRoiSide = 0.6 * viewMinSide;
  visibleRoi = {
    x: Math.floor(0.5 * viewWidth - 0.5 * viewRoiSide),
    y: Math.floor(0.5 * viewHeight - 0.5 * viewRoiSide),
    w: Math.ceil(viewRoiSide),
    h: Math.ceil(viewRoiSide),
  };

  yaw = null;
  pitch = null;
  latestBboxes = [];
  detectionFlags = [];
  livenessScores = [];
  prevLivenessTimestamp = null;
  calcLivenessScoreCalled = 0;
};

const horizontalFlipBbox = (bbox: Bbox, width: number) => {
  const newBbox = { ...bbox };
  newBbox.x = width - bbox.x - bbox.w;
  return newBbox;
};

export const bindCamera = async (
  detector: any,
  refs: Refs,
  functions: any,
  cameraIdx: string,
  cameraList: any
) => {
  const { userMedia, mirror } = await getCameraStream(
    cameraIdx,
    functions,
    cameraList
  );

  let continueLoop = true;

  const stopCb = () => {
    continueLoop = false;
  };

  functions.setBlockSwitch(false);
  // get external functions
  // tryAgain - изменить интерфейс для повторного нажатия
  // notify - вывод текста в поле оповещения
  const { notify, tryAgain } = functions;

  // init video and canvas
  const video = refs.videoRef.current;
  const viewCanvas = refs.canvasRef.current;
  const detectCanvas = document.createElement("canvas");
  if (!userMedia) return;

  if (video === null || viewCanvas === null) {
    console.log("Video Error");
    return;
  }

  video.srcObject = userMedia;
  let {
    videoWidth: viewWidth,
    videoHeight: viewHeight,
  }: { videoWidth: number; videoHeight: number } = await new Promise(
    (resolve, reject) => {
      video.onloadedmetadata = (e: Event) => {
        video.play();
        resolve(video);
      };
    }
  );
  viewCanvas.width = viewWidth;
  viewCanvas.height = viewHeight;

  // setup detection canvas to fit 640x480 as close as possible
  // without aspect ratio change
  const detectToView = Math.max(
    Math.max(viewWidth, viewHeight) / 640,
    Math.min(viewWidth, viewHeight) / 480
  );
  detectCanvas.width = Math.floor(viewWidth / detectToView);
  detectCanvas.height = Math.floor(viewHeight / detectToView);
  const viewCtx = viewCanvas.getContext("2d");
  if (!viewCtx) {
    console.log("Canvas Error");
    return;
  }
  viewCtx.lineWidth = 3;

  const detectCtx = detectCanvas.getContext("2d");
  if (!detectCtx) {
    console.log("Canvas Error");
    return;
  }
  // horizontal flip video frames
  applyHorizontalVideoFlip(mirror, viewCtx, detectCtx, viewWidth, detectCanvas);

  initDetectParams(minSide, detectCanvas, viewWidth, viewHeight);

  const cb = async () => {
    // check if device was rotated
    if (video.videoWidth !== viewWidth || video.videoHeight !== viewHeight) {
      viewWidth = video.videoWidth;
      viewHeight = video.videoHeight;

      viewCanvas.width = viewWidth;
      viewCanvas.height = viewHeight;

      detectCanvas.width = Math.floor(viewWidth / detectToView);
      detectCanvas.height = Math.floor(viewHeight / detectToView);

      // mirror back if frontal camera
      applyHorizontalVideoFlip(
        mirror,
        viewCtx,
        detectCtx,
        viewWidth,
        detectCanvas
      );

      initDetectParams(minSide, detectCanvas, viewWidth, viewHeight);
    }

    // draw video frame
    viewCtx.clearRect(0, 0, viewWidth, viewHeight);
    viewCtx.drawImage(video, 0, 0, viewWidth, viewHeight);

    // draw video frame on canvas for further extraction
    detectCtx.clearRect(0, 0, detectCanvas.width, detectCanvas.height);
    detectCtx.drawImage(video, 0, 0, detectCanvas.width, detectCanvas.height);
    let rgbaImage = null;
    // в моменте, когда изменяется текущая камера тут может случиться
    // исключение из-за нулевой ширины или высоты, поэтому ловим это
    // исключение и приостанавливаем обработку stopCb()
    try {
      const { data } = detectCtx.getImageData(
        0,
        0,
        detectCanvas.width,
        detectCanvas.height
      );
      rgbaImage = data;
    } catch (e) {
      stopCb();
      return;
    }
    const detectParams = {
      width: detectCanvas.width,
      height: detectCanvas.height,
      rgbaImage,
    };
    Object.assign(detectParams, staticDetectParams);
    const { bbox, angles, quality } = detector.detect(detectParams);

    let isGood = bbox != null;
    // scale bbox from detection size to view size
    if (isGood) {
      bbox.x = Math.floor(bbox.x * detectToView);
      bbox.y = Math.floor(bbox.y * detectToView);
      bbox.w = Math.floor(bbox.w * detectToView);
      bbox.h = Math.floor(bbox.h * detectToView);
    }

    detectionFlags = addDetectionFlags(detectionFlags, isGood);

    if (!isGood) {
      notify("info", "placeHead");
    } else {
      yaw = yaw !== null ? 0.8 * yaw + 0.2 * angles.yaw : angles.yaw;
      pitch = pitch !== null ? 0.8 * pitch + 0.2 * angles.pitch : angles.pitch;
      isGood = false;

      latestBboxes.push(bbox);
      if (latestBboxes.length > task.smoothBboxLength) {
        latestBboxes = latestBboxes.slice(1);
      }

      if (
        bbox.x < visibleRoi.x ||
        bbox.y < visibleRoi.y ||
        bbox.x + bbox.w > visibleRoi.x + visibleRoi.w ||
        bbox.y + bbox.h > visibleRoi.y + visibleRoi.h
      ) {
        notify("info", "placeHead");
      } else if (quality < task.qualityThreshold) {
        notify("info", "lowQuality");
      } else if (
        task.angles != null &&
        task.angles.pitch - pitch! > task.pitchThreshold
      ) {
        notify("info", "turnUp");
      } else if (
        task.angles != null &&
        pitch! - task.angles.pitch > task.pitchThreshold
      ) {
        notify("info", "turnDown");
      } else if (
        task.angles != null &&
        task.angles.yaw - yaw! > task.yawThreshold
      ) {
        notify("info", "turnRight");
      } else if (
        task.angles != null &&
        yaw! - task.angles.yaw > task.yawThreshold
      ) {
        notify("info", "turnLeft");
      } else {
        isGood = true;
        notify("info", "wait");
      }
    }
    // If image is good for 2 seconds and 2 frames we should extract it
    if (isGood) {
      const currentTimestamp = new Date();
      if (
        calcLivenessScoreCalled < task.calcLivenessScoreCount &&
        (prevLivenessTimestamp == null ||
          +currentTimestamp - +prevLivenessTimestamp >=
            task.calcLivenessScoreInterval)
      ) {
        prevLivenessTimestamp = currentTimestamp;
        calcLivenessScoreCalled += 1;

        const photoBlob = await new Promise((resolve: BlobCallback) => {
          viewCanvas.toBlob(resolve, "image/jpeg", 0.92);
        });

        const checkLiveness = async (catchedLivenessScores: number[]) => {
          const { liveness_score } = await retryFetch(
            `${apiUrl}calc_liveness`,
            {
              method: "POST",
              headers: {
                "Content-Type": photoBlob!.type,
                "Content-Length": photoBlob!.size.toString(),
                "liveness-bbox": `${bbox.x}_${bbox.y}_${bbox.w}_${bbox.h}`,
              },
              body: photoBlob,
            }
          );
          catchedLivenessScores.push(liveness_score);

          if (livenessScores.length >= task.calcLivenessScoreCount) {
            if (
              meanDetectionFlag(detectionFlags) >= task.successDetectionRate
            ) {
              const ok = meanLivenessScore(livenessScores) > 0.5;
              const message = ok ? "faceReal" : "faceFake";
              const result = message === "faceReal" ? "success" : "error";
              notify(result, message);
              stopCb();
              tryAgain();
            }

            // reset variables
            yaw = null;
            pitch = null;
            latestBboxes = [];
            detectionFlags = [];
            livenessScores = [];
            prevLivenessTimestamp = null;
            calcLivenessScoreCalled = 0;
          }
        };

        checkLiveness(livenessScores);
      }
    }

    if (meanDetectionFlag(detectionFlags) <= task.failDetectionRate) {
      yaw = null;
      pitch = null;
      latestBboxes = [];
      detectionFlags = [];
      livenessScores = [];
      prevLivenessTimestamp = null;
      calcLivenessScoreCalled = 0;
    }

    const smoothBbox = meanBbox(latestBboxes);
    if (smoothBbox != null) {
      viewCtx.beginPath();
      const flippedBbox = mirror
        ? horizontalFlipBbox(smoothBbox, viewWidth)
        : smoothBbox;
      viewCtx.rect(flippedBbox.x, flippedBbox.y, flippedBbox.w, flippedBbox.h);
      viewCtx.strokeStyle = isGood ? "green" : "red";
      viewCtx.stroke();
    }
    viewCtx.beginPath();
    viewCtx.rect(visibleRoi.x, visibleRoi.y, visibleRoi.w, visibleRoi.h);
    viewCtx.strokeStyle = "#036ab5";
    viewCtx.stroke();
  };

  // Main loop
  const loop = () => {
    if (userMedia.active && continueLoop) {
      cb();
      requestAnimationFrame(loop);
    }
  };
  requestAnimationFrame(loop);

  return stopCb;
};

export default bindCamera;
