import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type WarningProps = {
  open: boolean;
  userAgreed: (decision: boolean) => void;
  handleClose: () => void;
};

const Warning = (props: WarningProps) => {
  const { t } = useTranslation();

  const handleDecision = (decision: boolean) => {
    props.userAgreed(decision);
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("warning.title")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("warning.text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleDecision(true)}>
          Ok
        </Button>
        <Button variant="contained" onClick={() => handleDecision(false)}>
          {t("warning.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Warning;
