import { FC, HTMLProps, useCallback } from "react";
import styles from "./Main.module.scss";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import createDetector from "../../utils/createDetector";
import { Button, CircularProgress } from "@mui/material";
import Message from "../Message";
import Placeholder from "../Placeholder";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import useSwitch from "../../hooks/useSwitch";
import useButtonStyle from "../../hooks/useButtonStyle";
import useNotify from "../../hooks/useNotify";
import bindCamera from "../../utils/spoofme";
import { useTranslation } from "react-i18next";

export type Props = {
  termsAgreed: boolean;
  showWarning: () => void;
  addClasses?: string[];
} & HTMLProps<HTMLDivElement>;

const detector = createDetector();

const Main: FC<Props> = (props) => {
  const { showWarning, termsAgreed, addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );
  const { t } = useTranslation();

  const [stopCb, setStopCb] = useState(() => () => {
    console.log("init");
  });
  const [facewasmDataIsLoaded, setFacewasmDataIsLoaded] = useState(false);
  const [spoofmeIsActive, setSpoofmeIsActive] = useState(false);
  const {
    camera,
    switchAllowed,
    handleSwitch,
    setSwitchAllowed,
    handleDevices,
    cameraList,
    blockSwitch,
    setBlockSwitch,
    switchText,
    updateStream,
  } = useSwitch();
  const { buttonIcon, transformToTry, buttonText } =
    useButtonStyle(termsAgreed);
  const { notify } = useNotify();
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const refs = { videoRef, canvasRef };

  const handleSpoofMe = () => {
    if (!termsAgreed) {
      return showWarning();
    }
    setSpoofmeIsActive(true);
  };

  // TODO: переделать
  const functions = {
    notify, //функция сообщений
    tryAgain: () => {
      transformToTry();
      setSpoofmeIsActive(false);
      setSwitchAllowed(false); //функция, делающая видимой кнопку spoofme после распознавания
    },
    setSwitchAllowed,
    handleDevices,
    setBlockSwitch,
    updateStream,
  };

  // TODO: переделать
  useEffect(() => {
    if (!termsAgreed) return;
    setSpoofmeIsActive(true);
  }, [termsAgreed]);

  // TODO: переделать
  useEffect(() => {
    if (!spoofmeIsActive) return;
    try {
      const startCamAndReturnStopCallback = async () => {
        const returnedFunc = await bindCamera(
          detector,
          refs,
          functions,
          camera,
          cameraList
        );
        setStopCb(() => returnedFunc);
      };
      startCamAndReturnStopCallback();
    } catch (error) {
      setSpoofmeIsActive(false); // вернуть кнопку после неудачной попытки
    }
  }, [spoofmeIsActive, camera, cameraList]);

  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.name.includes("facewasm.data")) {
          setFacewasmDataIsLoaded(true);
        }
      });
    });
    observer.observe({ type: "resource", buffered: true });
  }, []);

  return (
    <div className={className} {...props}>
      {termsAgreed ? (
        <div className={styles["video-container"]}>
          <canvas ref={canvasRef} />
          <video ref={videoRef} playsInline muted />
        </div>
      ) : (
        <Placeholder />
      )}
      <div className={styles["btn-container"]}>
        {!facewasmDataIsLoaded ? (
          <div className={styles["data-loader"]}>
            <CircularProgress color="primary" />
            <span>{t("loadingData")}</span>
          </div>
        ) : (
          <>
            {!spoofmeIsActive && (
              <Button
                variant="contained"
                onClick={handleSpoofMe}
                endIcon={buttonIcon}
              >
                {buttonText}
              </Button>
            )}
            {switchAllowed && (
              <Button
                variant="contained"
                onClick={() => {
                  handleSwitch();
                  stopCb();
                }}
                disabled={blockSwitch}
                endIcon={<CameraswitchIcon />}
              >
                {switchText}
              </Button>
            )}
          </>
        )}
      </div>
      <Message />
    </div>
  );
};

export default Main;
