import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FaceIcon from "@mui/icons-material/Face";
import ReplayIcon from "@mui/icons-material/Replay";

const useButtonStyle = (userAgreed: boolean) => {
  const { t } = useTranslation();
  const [buttonIcon, setButtonIcon] = useState(<FaceIcon />);
  const [buttonText, setButtonText] = useState(t("button.spoof"));

  // кнопку spoofme в try again
  const transformToTry = () => {
    setButtonIcon(<ReplayIcon />); // заменить иконку
    setButtonText(t("button.try")); // заменить текст
  };

  // меняет перевод на кнопке повторить
  useEffect(() => {
    if (userAgreed) {
      setButtonText(t("button.try"));
    }
  }, [t, userAgreed, setButtonText]);

  return {
    buttonIcon,
    buttonText,
    transformToTry,
  };
};

export default useButtonStyle;
