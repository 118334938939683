import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { Provider } from "react-redux";
import { store } from "./store/index";
import "./translations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

declare global {
  interface Window {
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
    YANDEX_METRIC_ID: string;
  }
}

const { SENTRY_DSN, SENTRY_TRACES_RATE, SENTRY_ENVIRONMENT } = window;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: +SENTRY_TRACES_RATE,
  environment: SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
