import { useTranslation } from "react-i18next";
import Logo from "../Logo";
import Language from "../Language/Language";
import Description from "../Description";
import { useState } from "react";
import { FC, HTMLProps } from "react";
import styles from "./Navigation.module.scss";
import classNames from "classnames";

export type Props = { addClasses?: string[] } & HTMLProps<HTMLDivElement>;

const Navigation: FC<Props> = (props) => {
  const { i18n } = useTranslation();
  const [descriptionOpened, setDescriptionOpened] = useState(true);

  const toggleDescription = () => {
    setDescriptionOpened((prev) => !prev);
  };

  const { addClasses } = props;
  const className = classNames(
    [styles["container"], addClasses && [...addClasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      <div>
        <Logo handleClick={toggleDescription} />
        <Description opened={descriptionOpened} />
      </div>
      <div className={styles["lang-container"]}>
        {/* <h4>v1.0.4</h4> */}
        <Language
          title="Ru"
          onClick={() => i18n.changeLanguage("ru-RU")}
          selected={i18n.language.includes("ru")}
        />
        <Language
          title="En"
          onClick={() => i18n.changeLanguage("en")}
          selected={i18n.language.includes("en")}
        />
      </div>
    </div>
  );
};

export default Navigation;
