import Navigation from "./components/Navigation/Navigation";
import BottomSection from "./components/BottomSection/BottomSection";
import Warning from "./components/Warning";
import useAgreement from "./hooks/useAgreement";
import Main from "./components/Main";
import { useEffect } from "react";

function App() {
  const {
    showWarning,
    userAgreed,
    hideWarning,
    setUserAgreed,
    warningIsShown,
  } = useAgreement();

  useEffect(() => {
    (window as any).ym(window.YANDEX_METRIC_ID, "hit", window.location.href);
  }, []);

  return (
    <div className="app-container">
      <div className="max-width">
        <Navigation />
        <Main termsAgreed={userAgreed} showWarning={showWarning} />
      </div>
      <Warning
        open={warningIsShown}
        userAgreed={setUserAgreed}
        handleClose={hideWarning}
      />
      <BottomSection addClasses={["bottom-section"]} />
    </div>
  );
}

export default App;
